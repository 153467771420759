import Vue from 'vue';
import axios from "axios";
import { Currency, ICurrency } from "@shared/currency";
import { IJsonCart, IAddedToCartData } from "@modules/Cart/models";
import CartApi from "@modules/api/cart";
import NotificationsAPI, { NotificationApp, NotificationStatus, NotificationType } from "@modules/api/notifications";
import { INotification, INotificationCount, INotificationListForUi } from "@modules/api/notifications";

let $contextAxios = axios.create();
$contextAxios.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";

type AddToQuoteSetting = "Jewelershowcase" | "JewelershowcaseEmbedded" | "Both";

const PunchOutMeta = {
    countersketch: {
        atcText: "Tray"
    },
    matrixgold: {
        atcText: "Job"
    },
    everandever: {
        atcText: "Cart"
    }
}

export interface IJsonContext {
    LoginId: string;
    CurrentCultureName: string;
    IsInStullerFirst: boolean;
    IsInStullerFirstTrial: boolean;
    IsOrderApprovalByPassCheckout: boolean;
    OrderDoesNotRequireApproval: boolean;
    CanCheckout: boolean;
    IsInShowcase: boolean;
    HasJewelerShowcase: boolean;
    AllowIframeHostApi: boolean;
    AllowIframeHostApiDomainsCsvList: string;
    IframeHostAPIButtonText: string;
    IsInJewelerShowcase: boolean;
    StullerFirstPromotionalShippingCost: string;
    StullerFirstPromotionalShippingMethod: string;
    Currency: ICurrency;
    StullerFirstTrialData: { success: boolean; code: string; };
    HasScheduledOrder: boolean;
    ScheduledOrderCutoffTime: Date;
    IsInConsultantMode: boolean;
    IsToolsOnly: boolean;
    CanPromoteShowcase: boolean;
    HideShipDatesAndItemStatuses: boolean;
    ShowEstimatedShipDatesOverride: boolean;
    ShouldShowShipDateImmediately: boolean;
    UserNeedsToAgreeToTermsForReview: boolean;
    IsSterling: boolean;
    CartCount: number;
    Cart: IJsonCart;
    IsDev: boolean;
    IsArtisanAccount: boolean;
    HidePrices: boolean;
    HideAddToCart: boolean;
    HideBreadcrumbs: boolean;
    HideCartLinkInHeader: boolean;
    HideSearchbar: boolean;
    ShowShowcaseSalePrice: boolean;
    ShowPricePerCt: boolean;
    AccountCountry: string;
    UserFirstName: string;
    UserIsAssignedContact: boolean;

    ShowcaseTitle: string;
    ShowcaseEmailAddress: string;
    ShowcasePhoneNumber: string;
    ShowcaseCanShowSendAQuote: boolean;

    ControllerName: string;
    ActionName: string;

    IsInMetalsClub: boolean;
    AllowUniqueRequests: boolean;
    UserMemberId: string;
    SessionId: string;

    Sequence: number;
    RequestId: string;

    RecaptchaSiteKey: string;
    TurnToKey: string;
    GoogleMapApiKey: string;
    RequiresPanPad: boolean;

    //Cart: cart.Cart;
    PunchOutTheme: string;
    IsInCmsMode: boolean;
    IsEmployee: boolean;
    ShowDGLauncher: boolean;

    // Showcase
    ShowcaseCount: number;
    AddToQuote: AddToQuoteSetting | null;
}

export interface IShowcaseMasterViewModel {
    IsJewelerShowcase: boolean;
    AddToQuote: AddToQuoteSetting | null;
    IsIframeShowcase: boolean;
    HideAllTheThings: boolean;
    HideNavBarForShowcase: boolean;
    HideContactLinks: boolean;
    HidePrices: boolean;
    ContactOverride: string;
    DisableAddToCart: boolean;
    HideAddToCart: boolean;
    HideBreadcrumbs: boolean;
    DisableFavorites: boolean;
    OrderRequiresApproval: boolean;
    CartIsScheduled: any;
    CartCount: any;
    ShoppingCartIcon: string;
    ShowcaseRequiresAuthentication: boolean;
    ShowcaseDisplayViewModel: IShowcaseDisplayViewModel;
}

export interface IShowcaseDisplayViewModel {
    Title: string;
    Logo: string;
    PhoneNumber: string;
    EmailAddress: string;
    BackgroundColorClass: string;
    BackgroundPatternClass: string;
    ShowcaseSubdomain: string;
    AboutUsHtml: string;
    HasAboutUsPage: boolean;
    HasContactUsPage: boolean;
    CustomMessage: string;
    ContactUsHtml: string;
    IFrameShowNavigationBar: boolean;
}

export const CONTEXT_GETTERS = {
    LOGIN_ID: 'loginId',
    IS_IN_JEWELER_SHOWCASE: 'isInJewelerShowcase',
    SHOWCASE_REQUIRES_AUTHENTICATION: 'showcaseRequiresAuthentication',
    ADD_TO_QUOTE: 'addToQuote',
    IS_IN_SHOWCASE_MODE: 'isInShowcaseMode',
    IS_LOGGED_IN: 'isLoggedIn',
    IS_DEV: 'isDev',
    IS_ARTISAN_ACCOUNT: 'isArtisan',
    CONTEXT_OBJECT: "context",
    SHOWCASE_MENU_COUNT: 'showcaseMenuBadgeCount',
    CURRENCY: "currency",
    HAS_SCHEDULED_ORDER: "hasScheduledOrder",
    FORMATTED_SCHEDULED_ORDER_CUTOFF_DATE: "formattedScheduledOrderCutoffDate",
    SCHEDULED_ORDER_CUTOFF_DATE: "scheduledOrderCutoffDate",
    CART_COUNT: 'cartCount',
    CART_COUNT_COMPUTED: 'cartCountComputed',
    CART: 'cart',
    ADD_TO_CART_TEXT: 'addToCartText',
    ADD_TO_CART_ICON: 'addToCartIcon',
    IS_USING_IFRAME_HOST_API: 'isUsingIframeHostApi',
    IFRAME_HOST_API_DOMAIN: 'iframeHostApiDomain',
    CART_IS_LOADING: 'cartIsLoading',
    CART_ITEMS: 'cartItems',
    CAN_CHECKOUT: 'canCheckout',
    ADDED_TO_CART_DATAS: 'addedToCartDatas',
    SHIPPING_CONTAINER: 'shippingContainer',
    SHIPPING_RESPONSE: 'shippingResponse',
    SHOWCASE_MASTER_VIEWMODEL: 'showcaseMasterViewModel',
    SHOWCASE_PHONE_NUMBER: 'showcaseViewModelPhoneNumber',
    SHOWCASE_PHONE_NUMBER_HREF: 'showcaseViewModelPhoneNumberHref',
    SHOWCASE_HAS_ABOUT_US_PAGE: 'showcaseHasAboutUsPage',
    SHOWCASE_ABOUT_US_HTML: 'showcaseAboutUsHtml',
    SHOWCASE_HAS_CONTACT_US_PAGE: 'showcaseHasContactUsPage',
    SHOWCASE_CONTACT_US_HTML: 'showcaseContactUsHtml',
    SHOWCASE_HIDE_CART_LINK_IN_HEADER: 'showcaseHideCartLinkInHeader',
    SHOWCASE_HIDE_SEARCHBAR: 'showcaseHideSearchBar',
    SHOWCASE_HIDE_NAV_BAR: 'showcaseHideNavBar',
    SHOWCASE_HIDE_CONTACT_LINKS: 'showcaseHideContactLinks',
    SHOWCASE_DISABLE_FAVORITES: 'showcaseDisableFavorites',
    SHOWCASE_ORDER_REQUIRES_APPROVAL: 'showcaseOrderRequiresApproval',
    SHOWCASE_HIDE_PRICES: 'showcaseHidePrices',
    UNREAD_NOTIFICATION_COUNT: 'unreadNotificationCount',
    NOTIFICATION_LIST: 'notificationList',
    USER_FIRST_NAME: 'userFirstName',
    USER_IS_ASSIGNED_CONTACT: 'userIsAssignedContact',
}

export const CONTEXT_MUTATIONS = {
    SET_CONTEXT: 'setContext',
    SET_SHOWCASE_COUNT: 'setShowcaseMenuBadgeCount',
    SET_CART_COUNT: 'setCartCount',
    SET_CART: 'setCart',
    SET_CART_IS_LOADING: 'setCartIsLoading',
    SET_ALL_CART_ITEMS: 'setCartItems',
    ADD_ADDED_TO_CART_DATA: 'addAddedToCartData',
    REMOVE_ADDED_TO_CART_DATA: 'removeAddedToCartData',
    REMOVE_ALL_ADDED_TO_CART_DATA: 'removeAllAddedToCartData',
    SET_CART_ITEM: 'setCartItem',
    REMOVE_CART_ITEM: 'removeCartItem',
    SET_SHIPPING_RESPONSE: 'setShippingResponse',
    SET_SHOWCASE_MASTER_VIEWMODEL: 'setShowcaseMasterViewModel',
    SET_UNREAD_NOTIFICATION_COUNT: 'setUnreadNotificationCount',
    SET_NOTIFICATION_LIST: 'setNotificationList',
    SET_NOTIFICATION_ITEM: 'setNotificationItem'
}

export const CONTEXT_ACTIONS = {
    GET_AND_SET_CONTEXT: 'getAndSetContext',
    SET_SHOWCASE_COUNT: 'setShowcaseMenuBadgeCount',
    GET_AND_SET_CART_COUNT: 'getAndSetCartCount',
    GET_AND_SET_CART: 'getAndSetCart',
    ADD_ADDED_TO_CART_DATA: 'addAddedToCartData',
    REMOVE_ADDED_TO_CART_DATA: 'removeAddedToCartData',
    REMOVE_ALL_ADDED_TO_CART_DATA: 'removeAllAddedToCartData',
    REFRESH_ALL_CART_ITEMS: 'refreshAllCartItems',
    UPDATE_CART_ITEM: 'updateCartItem',
    DELETE_CART_ITEM: 'deleteCartItem',
    MOVE_CART_ITEM: 'moveCartItem',
    LOAD_SHIPPING_OPTIONS: 'loadShippingOptions',
    GET_AND_SET_SHOWCASE_MASTER_VIEWMODEL: 'getAndSetShowcaseMasterViewModel',
    GET_AND_SET_UNREAD_NOTIFICATION_COUNT: 'getAndSetUnreadNotificationCount',
    GET_AND_SET_NOTIFICATION_LIST: 'getAndSetNotificationList',
    SET_NOTIFICATION_STATUS: 'setNotificationStatus',
    MARK_ALL_READ_NOTIFICATION_LIST: 'markAllReadNotificationList',
    CHANGE_LOCAL_NOTIFICATION_COUNT: 'changeLocalNotificationCount',
}

export const contextModule = {
    namespaced: true,
    state: () => ({
        context: null,
        shippingResponse: null,
        showcaseData: null,
        cartIsLoading: false,
        addedToCartDatas: [] as IAddedToCartData[],
        UnreadNotificationCount: null as unknown as INotificationCount,
        NotificationList: null as unknown as INotificationListForUi
    }),
    mutations: {
        [CONTEXT_MUTATIONS.SET_CONTEXT](state, contextResponse) {
            Vue.set(state, "context", contextResponse);
            Vue.set(state.context, "Currency", new Currency(contextResponse.Currency));
        },
        [CONTEXT_MUTATIONS.SET_CART_COUNT](state, cartCount) {
            Vue.set(state.context, "CartCount", cartCount);
        },
        [CONTEXT_MUTATIONS.SET_CART](state, cart) {
            Vue.set(state.context, "Cart", cart);
        },
        [CONTEXT_MUTATIONS.SET_CART_IS_LOADING](state, value) {
            Vue.set(state, "cartIsLoading", value);
        },
        [CONTEXT_MUTATIONS.SET_ALL_CART_ITEMS](state, cartItems) {
            Vue.set(state.context.Cart, "CartItems", cartItems);
        },
        [CONTEXT_MUTATIONS.SET_CART_ITEM](state, cartItem) {
            let cartItemIndex = state.context.Cart.CartItems.map(c => c.Id).indexOf(cartItem.Id);
            state.context.Cart.CartItems.splice(cartItemIndex, 1, cartItem);
        },
        [CONTEXT_MUTATIONS.REMOVE_CART_ITEM](state, cartItem) {
            let cartItemIndex = state.context.Cart.CartItems.map(c => c.Id).indexOf(cartItem.Id);
            if (cartItemIndex < 0)
                return;

            let cartItems = state.context.Cart.CartItems.slice();
            cartItems.splice(cartItemIndex, 1);
            Vue.set(state.context.Cart, "CartItems", cartItems);
        },
        [CONTEXT_MUTATIONS.ADD_ADDED_TO_CART_DATA](state, addedToCartData: IAddedToCartData) {
            state.addedToCartDatas.push(addedToCartData);
        },
        [CONTEXT_MUTATIONS.REMOVE_ADDED_TO_CART_DATA](state, id) {
            let index = state.addedToCartDatas.map(a => a.Id).indexOf(id);
            if (index < 0)
                return;

            let datas = state.addedToCartDatas.slice();
            datas.splice(index, 1);
            Vue.set(state, "addedToCartDatas", datas);
        },
        [CONTEXT_MUTATIONS.REMOVE_ALL_ADDED_TO_CART_DATA](state) {
            Vue.set(state, "addedToCartDatas", []);
        },
        [CONTEXT_MUTATIONS.SET_SHIPPING_RESPONSE](state, shippingResponse) {
            //Vue.set(state, 'shippingResponse', shippingResponse);
        },
        [CONTEXT_MUTATIONS.SET_SHOWCASE_COUNT](state, showcaseCount) {
            if (state.context) {
                Vue.set(state.context, "ShowcaseCount", showcaseCount);
            }
        },
        [CONTEXT_MUTATIONS.SET_SHOWCASE_MASTER_VIEWMODEL](state, showcaseData) {
            Vue.set(state, "showcaseData", showcaseData);
        },
        [CONTEXT_MUTATIONS.SET_UNREAD_NOTIFICATION_COUNT](state, notificationCount: INotificationCount) {
            Vue.set(state, "UnreadNotificationCount", notificationCount);
        },
        [CONTEXT_MUTATIONS.SET_NOTIFICATION_LIST](state, { notificationList, append = false }: { notificationList: INotificationListForUi, append: boolean }) {
            if (state.context) {
                if (append && state.NotificationList) {
                    notificationList.notifications = [
                        ...state.NotificationList.notifications, 
                        ...notificationList.notifications
                    ]
                }
                Vue.set(state, "NotificationList", notificationList);
            }
        },
        [CONTEXT_MUTATIONS.SET_NOTIFICATION_ITEM](state, notification: INotification) {
            let notificationIndex = state.NotificationList.notifications.map(c => c.id).indexOf(notification.id);
            state.NotificationList.notifications.splice(notificationIndex, 1, notification);
        },
    },
    getters: {
        [CONTEXT_GETTERS.LOGIN_ID](state): string {
            return state.context?.LoginId;
        },
        [CONTEXT_GETTERS.USER_FIRST_NAME](state): string {
            return state.context?.UserFirstName ?? false;
        },
        [CONTEXT_GETTERS.USER_IS_ASSIGNED_CONTACT](state): string {
            return state.context?.UserIsAssignedContact;
        },
        [CONTEXT_GETTERS.IS_DEV](state): boolean {
            return state.context?.IsDev;
        },
        [CONTEXT_GETTERS.IS_ARTISAN_ACCOUNT](state): boolean {
            return state.context?.IsArtisanAccount;
        },
        [CONTEXT_GETTERS.IS_IN_JEWELER_SHOWCASE](state): boolean {
            return state.context?.IsInJewelerShowcase;
        },
        [CONTEXT_GETTERS.SHOWCASE_REQUIRES_AUTHENTICATION](state): boolean {
            return state.context?.ShowcaseRequiresAuthentication;
        },
        [CONTEXT_GETTERS.ADD_TO_QUOTE](state): AddToQuoteSetting | null {
            return state.context?.AddToQuote ?? null;
        },
        [CONTEXT_GETTERS.IS_IN_SHOWCASE_MODE](state): boolean {
            return state.context?.IsInShowcase;
        },
        [CONTEXT_GETTERS.IS_LOGGED_IN](state, getters): boolean {
            return getters[CONTEXT_GETTERS.LOGIN_ID] !== null || getters[CONTEXT_GETTERS.IS_IN_JEWELER_SHOWCASE];
        },
        [CONTEXT_GETTERS.CONTEXT_OBJECT](state) {
            return state.context;
        },
        [CONTEXT_GETTERS.SHOWCASE_MENU_COUNT](state): number {
            return state.context?.ShowcaseCount || 0;
        },
        [CONTEXT_GETTERS.CURRENCY](state) {
            return state.context?.Currency;
        },
        [CONTEXT_GETTERS.CAN_CHECKOUT](state) {
            return state.context?.CanCheckout;  
        },
        [CONTEXT_GETTERS.HAS_SCHEDULED_ORDER](state): boolean {
            return state.context?.HasScheduledOrder || false;
        },
        [CONTEXT_GETTERS.SCHEDULED_ORDER_CUTOFF_DATE](state) {
            return state.context?.ScheduledOrderCutoffTime;
        },
        [CONTEXT_GETTERS.SCHEDULED_ORDER_CUTOFF_DATE](state) {
            return state.context?.ScheduledOrderCutoffTime;
        },
        [CONTEXT_GETTERS.FORMATTED_SCHEDULED_ORDER_CUTOFF_DATE](state) {
            return state.context?.FormattedScheduledOrderCutoffTime;
        },
        [CONTEXT_GETTERS.CART_COUNT_COMPUTED](state): number {
            if (state.context?.Cart?.CartItems != null)
                return state.context?.Cart?.CartItems?.length;

            return state.context?.CartCount || 0;
        },
        [CONTEXT_GETTERS.CART](state): IJsonCart {
            return state.context?.Cart;
        },
        [CONTEXT_GETTERS.ADD_TO_CART_TEXT](state, getters): string {
            const theme = state.context?.PunchOutTheme;
            if (theme !== '' && PunchOutMeta[theme]?.atcText) {
                return PunchOutMeta[theme].atcText;
            }

            if (state.context?.IsInJewelerShowcase && !state.context?.IsOrderApprovalByPassCheckout && !state.context?.OrderDoesNotRequireApproval && !getters[CONTEXT_GETTERS.IS_USING_IFRAME_HOST_API]) {
                return "Quote";
            }

            return "Cart";
        },
        [CONTEXT_GETTERS.ADD_TO_CART_ICON](state): string {
            let icon = 'fa ';

            if (state.context?.IsInShowcase && state.showcaseData) {
                icon += state.showcaseData?.ShoppingCartIcon;
            } else {
                icon += state.context?.HasScheduledOrder ? 'fa-clock-o' : 'fa-shopping-cart';
            }

            return icon;
        },
        [CONTEXT_GETTERS.IS_USING_IFRAME_HOST_API](state): boolean {
            return (state.context?.AllowIframeHostApi && this.IframeHostApiDomain != null);
        },
        [CONTEXT_GETTERS.IS_USING_IFRAME_HOST_API](state): boolean {
            var parentDomain = null as any
            if (state.context?.AllowIframeHostApi && window.sessionStorage) {
                parentDomain = window.sessionStorage.getItem("IframeHostDomain")
                if (!parentDomain) {
                    var match = document.referrer.match(/^https?:\/\/[^\/]+/i)
                    if (match) {
                        parentDomain = match[0]
                        window.sessionStorage.setItem("IframeHostDomain", parentDomain)
                    }
                }
                if (!(parentDomain && state.context?.AllowIframeHostApiDomainsCsvList && (state.context?.AllowIframeHostApiDomainsCsvList.split(',').indexOf(parentDomain) !== -1 || state.context?.AllowIframeHostApiDomainsCsvList == '*'))) {
                    parentDomain = null
                    window.sessionStorage.removeItem("IframeHostDomain")
                }
            }
            return parentDomain
        },
        [CONTEXT_GETTERS.CART_IS_LOADING](state): boolean {
            return state.cartIsLoading || false;
        },
        [CONTEXT_GETTERS.ADDED_TO_CART_DATAS](state): boolean {
            return state.addedToCartDatas;
        },
        [CONTEXT_GETTERS.SHIPPING_CONTAINER](state) {
            return {
                GetDropShip: () => state.context?.Cart?.DropShip,
                GetOrderedShipments: () => state.context?.Cart?.Shipments,
                GetPaymentMethod: () => state.context?.Cart?.PaymentMethod,
                GetShipToAccountNumber: () => state.context?.Cart?.ShipToAccountNumber,
                GetShippingPromoCode: () => state.context?.Cart?.ShippingPromoCode,
                OnShippingOptionsResponse: null,
                GetShipmentsObservable: () => state.context?.Cart?.Shipments,
                GetOracleLocationId: () => null,
                GetIsPickupLocation: () => state.context?.Cart?.IsPickupLocation,
                Shipments: state.context?.Cart?.Shipments,
                ShipCompleteMethod: state.context?.Cart?.ShipCompleteMethod,
                OrderCutOffDate: state.context?.Cart?.OrderCutOffDate,
                UndoAllExpeditedItems: false
            }
        },
        [CONTEXT_GETTERS.SHIPPING_RESPONSE](state): any /*ShippingOptionsResponse*/ {
            return state.shippingResponse;
        },
        [CONTEXT_GETTERS.SHOWCASE_HIDE_CART_LINK_IN_HEADER](state) {
            return state.context?.HideCartLinkInHeader || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_HIDE_SEARCHBAR](state) {
            return state.context?.HideSearchbar || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_MASTER_VIEWMODEL](state): IShowcaseMasterViewModel {
            return state.showcaseData;
        },
        [CONTEXT_GETTERS.SHOWCASE_HIDE_NAV_BAR](state) {
            return state.showcaseData?.HideNavBarForShowcase || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_HIDE_CONTACT_LINKS](state) {
            return state.showcaseData?.HideContactLinks || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_DISABLE_FAVORITES](state) {
            return state.showcaseData?.DisableFavorites || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_ORDER_REQUIRES_APPROVAL](state) {
            return state.showcaseData?.OrderRequiresApproval || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_HIDE_PRICES](state) {
            return state.showcaseData?.HidePrices || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_PHONE_NUMBER](state) {
            let phoneNumber = state.showcaseData?.ShowcaseDisplayViewModel?.PhoneNumber || "800-877-7777";
            return phoneNumber;
        },
        [CONTEXT_GETTERS.SHOWCASE_PHONE_NUMBER_HREF](state, getters) {
            return `tel:${getters[CONTEXT_GETTERS.SHOWCASE_PHONE_NUMBER]}`;
        },
        [CONTEXT_GETTERS.SHOWCASE_HAS_ABOUT_US_PAGE](state) {
            return state.showcaseData?.ShowcaseDisplayViewModel?.HasAboutUsPage || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_ABOUT_US_HTML](state) {
            return state.showcaseData?.ShowcaseDisplayViewModel?.AboutUsHtml || '';
        },
        [CONTEXT_GETTERS.SHOWCASE_HAS_CONTACT_US_PAGE](state) {
            return state.showcaseData?.ShowcaseDisplayViewModel?.HasContactUsPage || false;
        },
        [CONTEXT_GETTERS.SHOWCASE_CONTACT_US_HTML](state) {
            return state.showcaseData?.ShowcaseDisplayViewModel?.ContactUsHtml || '';
        },
        [CONTEXT_GETTERS.UNREAD_NOTIFICATION_COUNT](state): INotificationCount {
            return state.UnreadNotificationCount || { total: 0, important: 0 };
        },
        [CONTEXT_GETTERS.NOTIFICATION_LIST](state): INotificationListForUi {
            return state.NotificationList;
        },
    },
    actions: {
        async [CONTEXT_ACTIONS.GET_AND_SET_CONTEXT]({ commit }) {
            return $contextAxios.get("/homes/getjsoncontext", {}).then(response => {
                commit(CONTEXT_MUTATIONS.SET_CONTEXT, response.data);
            })
        },
        [CONTEXT_ACTIONS.SET_SHOWCASE_COUNT]({ commit }, payload) {
            commit(CONTEXT_MUTATIONS.SET_SHOWCASE_COUNT, payload);
        },
        [CONTEXT_ACTIONS.GET_AND_SET_CART_COUNT]({ commit }) {            
            return CartApi.getCartCount().then(response => {
                commit(CONTEXT_MUTATIONS.SET_CART_COUNT, response);
            })
        },
        [CONTEXT_ACTIONS.GET_AND_SET_CART]({ commit }) {
            commit(CONTEXT_MUTATIONS.SET_CART_IS_LOADING, true);
            return CartApi.getCart().then(response => {
                commit(CONTEXT_MUTATIONS.SET_CART, response);
                commit(CONTEXT_MUTATIONS.SET_CART_IS_LOADING, false);
            });
        },
        [CONTEXT_ACTIONS.REFRESH_ALL_CART_ITEMS]({ commit, state }) {
            if (state.context?.Cart != null) {
                return CartApi.getCart().then(response => {
                    commit(CONTEXT_MUTATIONS.SET_ALL_CART_ITEMS, response?.CartItems);
                })
            }
            else {
                return Promise.resolve();
            }
        },
        [CONTEXT_ACTIONS.UPDATE_CART_ITEM]({ commit }, cartItemPostModel) {
            return CartApi.updateCartItem(cartItemPostModel).then(response => {
                if (response.success)
                    commit(CONTEXT_MUTATIONS.SET_CART_ITEM, response?.cartItem);
                else {
                    //maybe do some store errors??
                }
                return response;
            })
        },
        [CONTEXT_ACTIONS.DELETE_CART_ITEM]({ commit }, cartItem) {
            return $contextAxios.post(cartItem.DeleteUrl).then(response => {
                if (response.data.success)
                    commit(CONTEXT_MUTATIONS.REMOVE_CART_ITEM, cartItem);
                else {
                    //maybe do some store errors??
                }
                return response.data;
            })
        },
        [CONTEXT_ACTIONS.MOVE_CART_ITEM]({ commit }, cartItem) {
            return $contextAxios.post(cartItem.ButtonUrl).then(response => {
                if (response.data.success)
                    commit(CONTEXT_MUTATIONS.REMOVE_CART_ITEM, cartItem);
                else {
                    //maybe do some store errors??
                }
                return response.data;
            })
        },
        [CONTEXT_ACTIONS.ADD_ADDED_TO_CART_DATA]({ commit }, addedToCartData: IAddedToCartData) {
            commit(CONTEXT_MUTATIONS.ADD_ADDED_TO_CART_DATA, addedToCartData);
        },
        [CONTEXT_ACTIONS.REMOVE_ADDED_TO_CART_DATA]({ commit }, id) {
            commit(CONTEXT_MUTATIONS.REMOVE_ADDED_TO_CART_DATA, id);
        },
        [CONTEXT_ACTIONS.REMOVE_ALL_ADDED_TO_CART_DATA]({ commit }) {
            commit(CONTEXT_MUTATIONS.REMOVE_ALL_ADDED_TO_CART_DATA);
        },
        [CONTEXT_ACTIONS.GET_AND_SET_SHOWCASE_MASTER_VIEWMODEL]({ commit }) {
            return $contextAxios.get("/homes/getshowcasedataviewmodel", {}).then(response => {
                commit(CONTEXT_MUTATIONS.SET_SHOWCASE_MASTER_VIEWMODEL, response.data);
            })
        },
        [CONTEXT_ACTIONS.LOAD_SHIPPING_OPTIONS]({ commit, state, getters }) {
            //state.context?.Cart?.Shipments?.forEach(shipment => {
            //    ShippingApi.getShippingOptions(false, true, state.context?.Cart?.CartItems, shipment, getters[CONTEXT_GETTERS.SHIPPING_CONTAINER]).then(response => {
            //        console.log(response);
            //        commit(CONTEXT_MUTATIONS.SET_SHIPPING_RESPONSE, response);
            //    });
            //});
        },
        [CONTEXT_ACTIONS.GET_AND_SET_UNREAD_NOTIFICATION_COUNT]({ commit }) {
            return NotificationsAPI.count(NotificationApp.StullerCom, NotificationStatus.UnRead).then(response => {
                commit(CONTEXT_MUTATIONS.SET_UNREAD_NOTIFICATION_COUNT, response);
            })
        },
        [CONTEXT_ACTIONS.GET_AND_SET_NOTIFICATION_LIST]({ dispatch, commit }, payload: { app?: NotificationApp, limit?: number, important?: boolean, status?: NotificationStatus, before?: string, types?: NotificationType[] }) {
            return NotificationsAPI.list(payload?.app, payload?.limit, payload?.important, payload?.status, payload?.before, payload?.types).then(response => {
                commit(CONTEXT_MUTATIONS.SET_NOTIFICATION_LIST, { notificationList: response, append: payload?.before != null });
                dispatch(CONTEXT_ACTIONS.GET_AND_SET_UNREAD_NOTIFICATION_COUNT);
            })
        },
        [CONTEXT_ACTIONS.SET_NOTIFICATION_STATUS]({ dispatch, commit }, payload: { notificationId: string,  status: NotificationStatus, isImportant: boolean }) {
            return NotificationsAPI.updateStatus(payload.notificationId, payload.status).then(response => {
                commit(CONTEXT_MUTATIONS.SET_NOTIFICATION_ITEM, response);
                const isMarkingAsRead = payload.status == NotificationStatus.Read;
                const countAffect = isMarkingAsRead ? -1 : 1;
                dispatch(CONTEXT_ACTIONS.CHANGE_LOCAL_NOTIFICATION_COUNT, {countAdded: countAffect, isImportant: payload.isImportant});
            })
        },
        [CONTEXT_ACTIONS.MARK_ALL_READ_NOTIFICATION_LIST]({ dispatch }) {
            return NotificationsAPI.markAllAsRead().then(response => {
                dispatch(CONTEXT_ACTIONS.CHANGE_LOCAL_NOTIFICATION_COUNT, { countAdded: 0, isImportant: true, setCountStraightUp: true });
            })
        },
        [CONTEXT_ACTIONS.CHANGE_LOCAL_NOTIFICATION_COUNT]({ commit, state }, payload: { countAdded: number, isImportant: boolean, setCountStraightUp?: boolean }) {
            const newTotal = payload.setCountStraightUp ? payload.countAdded : state.UnreadNotificationCount.total + payload.countAdded;
            let newImportantCount = state.UnreadNotificationCount.important;
            if (payload.isImportant) {
                newImportantCount = payload.setCountStraightUp ? payload.countAdded : state.UnreadNotificationCount.important + payload.countAdded;
            }

            const countObj: INotificationCount = { important: newImportantCount, total: newTotal };
            const params = {
                app: NotificationApp.StullerCom,
                status: NotificationStatus.UnRead,
                countOnly: true
            };
            NotificationsAPI.setCountCache(params, countObj);

            commit(CONTEXT_MUTATIONS.SET_UNREAD_NOTIFICATION_COUNT, countObj);
        },
    }
}
