import axios from 'axios';

export interface ISystemSettingValue {
    value: string;
}

class SystemSettingsAPI {
    getSettingValue(settingName: string): Promise<string> {

        const params = { settingName, api_fmt: "json" };

        return axios.get('/systemsettings/value', { params })
            .then(response => response?.data?.value)
            .catch(err => console.log(err));
    }
}

export default new SystemSettingsAPI();