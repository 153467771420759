import axios from 'axios';
import qs from 'qs';
import jwtauth from './jwtauth'
import { Cache } from '../utility/cache';
import { env } from '../env'

export enum NotificationApp {
	StullerCom = "STULLERCOM"
}

export enum NotificationType {
	// Shipment Types
  ShipmentNew = 'SHIPMENT_NEW',
  ShipmentUpdate = 'SHIPMENT_UPDATE',
  ShipmentSummary = 'SHIPMENT_SUMMARY',
  ShipmentGreenCheck = 'SHIPMENT_GREEN_CHECK',
  ShipmentGreenTruck = 'SHIPMENT_GREEN_TRUCK',
  ShipmentYellowExclamation = 'SHIPMENT_YELLOW_EXCLAMATION',

  // Other Types
  AccountUpdate = 'ACCOUNT_UPDATE',
  FeatureNew = 'FEATURE_NEW',
  MessageNew = 'MESSAGE_NEW',
  MembershipUpdate = 'MEMBERSHIP_UPDATE',
  ProjectUpdate = 'PROJECT_UPDATE',
  ReturnUpdate = 'RETURN_UPDATE',
  StatementNew = 'STATEMENT_NEW',
  BlueCheck = 'BLUE_CHECK',
  BlueHourglass = 'BLUE_HOURGLASS',
  BlueInfo = 'BLUE_INFO',
  GreenCheck = 'GREEN_CHECK',
  RedExclamation = 'RED_EXCLAMATION',
  NewGreenCalendar = 'NEW_GREEN_CALENDAR',
  NewGreenInfo = 'NEW_GREEN_INFO',
  NewGreenSparkles = 'NEW_GREEN_SPARKLES',
  NewGreenTag = 'NEW_GREEN_TAG',
  YellowExclamation = 'YELLOW_EXCLAMATION'
}

export enum NotificationStatus {
	Read = "READ",
	UnRead = "UNREAD"
}

export interface INotificationMetadata {
	title: string,
	body: string,
	url: string
}

export interface INotification {
	app: NotificationApp,
	userId: string,
	type: NotificationType,
	id: string,
	status: NotificationStatus,
	important: boolean,
	metadata: INotificationMetadata,
	createdDate: Date,
	expiresDate: Date
}

export interface INotificationCount {
	total: number
	important: number
}

export interface INotificationForUi extends INotification {
	isLoading: boolean;
}

export interface INotificationListForUi {
	notifications: INotificationForUi[]
	before?: string
}

class NotificationsAPI {
	// TODO: this needs some dev/live switchin
	protected readonly notificationsInstance = axios.create({
		baseURL: env.NOTIFICATIONS_BASE_URL,
		paramsSerializer: function (params) {
			return qs.stringify(params, { indices: false });
		}
	});
	protected readonly baseApp = NotificationApp.StullerCom;
	protected readonly baseLimit = 40;
	private readonly cache: Cache = new Cache('notifications');

	constructor() {
		// Add auth interceptor to attach the authorization header and refresh when needed
		this.notificationsInstance.interceptors.request.use(
			async (config) => {
				const token = await jwtauth.getJwtToken('Cognito');

				if (token) {
					config.headers.common['Authorization'] = `Bearer ${token}`;
				} else {
					delete config.headers.common['Authorization'];
				}

				return config;
			},
			error => {
				return Promise.reject(error);
			}
		);
	}

	async list(app?: NotificationApp, limit?: number, important?: boolean, status?: NotificationStatus, before?: string, type?: NotificationType[]): Promise<INotificationListForUi> {
		app = app || this.baseApp;
		limit = limit || this.baseLimit;

		const params = {
			app,
			limit,
			important,
			status,
			before,
			type
		}

		return this.notificationsInstance.get('/notifications', { params }).then(response => {
			return {
				notifications: response.data.data.map(this.convertNotificationForUi),
				before: response.data.before
			};
		}).catch(err => {
			return Promise.reject(err);
		});
	}

	async count(app?: NotificationApp, status?: NotificationStatus): Promise<INotificationCount> {
		app = app || this.baseApp;

		const params = {
			app,
			status,
			countOnly: true
		};

		const cacheKey = this.getCountCacheKey(params);
		const cacheValue = this.cache.Retrieve(cacheKey)?.value;
				
		return cacheValue || await this.notificationsInstance.get('/notifications', { params }).then(response => {
			const retVal = response.data;
			this.setCountCache(params, retVal);
			return retVal;
		}).catch(err => {
			return Promise.reject(err);
		});
	}

	setCountCache(params, value: INotificationCount) {
		const cacheKey = this.getCountCacheKey(params);
		this.cache.Store(cacheKey, { value }, 'session', 10000);
	}

	protected getCountCacheKey(params) {
		return `count-${JSON.stringify(params)}`
    }

	get(id: string): Promise<INotificationForUi> {
		return this.notificationsInstance.get(`/notifications/${id}`).then(response => {
			return this.convertNotificationForUi(response.data);
		}).catch(err => {
			return Promise.reject(err);
		});
	}

	updateStatus(id: string, status: NotificationStatus): Promise<INotificationForUi> {
		return this.notificationsInstance.patch(`/notifications/${id}`, { status }).then(response => {
			return this.convertNotificationForUi(response.data);
		}).catch(err => {
			return Promise.reject(err);
		});
	}

	delete(id: string): Promise<any> {
		return this.notificationsInstance.delete(`/notifications/${id}`).then(response => {
			return response.data;
		}).catch(err => {
			return Promise.reject(err);
		});
	}

	markAllAsRead(app?: NotificationApp): Promise<any> {
		app = app || NotificationApp.StullerCom;
		return this.notificationsInstance.post(`/notifications/read`, { app }).then(response => {
			return response.data;
		}).catch(err => {
			return Promise.reject(err);
		});
	}

	protected convertNotificationForUi(notification: INotification): INotificationForUi {
		return Object.assign(notification, { isLoading: false }) as INotificationForUi;
    }
}

export default new NotificationsAPI();
