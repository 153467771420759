import Dayjs from "dayjs";
var advancedFormat = require('dayjs/plugin/advancedFormat');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');
var localizedFormat = require('dayjs/plugin/localizedFormat');
import "dayjs/plugin/utc"; //hack for the utc plugin.  it literally won't compile without this line.  found here: https://github.com/ArkEcosystem/platform-sdk/pull/191/files
var isToday = require('dayjs/plugin/isToday')
var isTomorrow = require('dayjs/plugin/isTomorrow')

Dayjs.extend(utc);
Dayjs.extend(timezone);
Dayjs.extend(advancedFormat);
Dayjs.extend(localizedFormat);
Dayjs.extend(isToday);
Dayjs.extend(isTomorrow);

export const dateTimeMixin = {
    data() {
        return {
            defaultTimelessFormat: 'YYYY/MM/DD',
            defaultLocalLongFormat: 'llll',
            defaultLongFormat: 'ddd MMMM Do, YYYY'
        }
    },
    methods: {
        dtGetCurrentDate(): Dayjs.Dayjs {
            return Dayjs();
        },
        dtGetCurrentDateNoTime(date: Dayjs.Dayjs): Dayjs.Dayjs {
            return Dayjs(date.format((this as any).defaultTimelessFormat));
        },
        dtGetDateTime(date: Date): Dayjs.Dayjs {
            return Dayjs(date);
        },
        dtGetLongDateString(date: string) {
            return this.dtFormatDate(date, (this as any).defaultLocalLongFormat);
        },
        dtFormatDate(date: string, format: string) {
            const d = Dayjs(new Date(date))
            if (d.isUTC())
                return d.utc().local().format(format);
            else
                return d.format(format);
        }
    }
}