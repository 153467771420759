import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { readCookie } from '../utility/cookie'

class JwtAuthAPI {	
	protected readonly jwtAuthInstance = axios.create({
		baseURL: '/jwtauth'
	});
	/**
	 * Seconds to expire token early (5 minutes).
	 */
	protected EXPIRE_BUFFER = 300;

	/**
	 * Validate the current cached JWT token (include `EXPIRE_BUFFER`).
	 */
	private validateJwtToken (token: string): boolean {
		try {
			const decoded = jwtDecode<JwtPayload>(token);
			const currentTime = (new Date).getTime() / 1000;

			if (decoded.exp - currentTime < this.EXPIRE_BUFFER) {
				return false;
			}

			return true;
		} catch (error) {}

		return false
	}

	/**
	 * Try to get a valid JWT token from cache or refresh from backend.
	 */
	async getJwtToken(ssoSourceSystem: 'Cognito' | 'Keycloak'): Promise<string> {
		let cookieName = `${ssoSourceSystem.toLowerCase()}_session_token`
		let token = readCookie(cookieName);
		if (token == null || !this.validateJwtToken(token)) {
			try {
				const result = await this.jwtAuthInstance.get('/getjwttoken', {
					params: {
						ssoSourceSystem
					}
				});
				token = result.data
			} catch (err) {
				console.log(err)
				token = null
            }
		}
		return token
	}
}

export default new JwtAuthAPI();
