/**
 * Creates a cookie for the current domain.
 * Leave off days to set expire to Session.
 */
function createCookie(name: string, value: string, days?: number) {
    let expires = "";

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = escape(name) + "=" + escape(value) + expires + "; path=/";
}

/**
 * Reads the cookie.
 */
function readCookie(name: string) {
    const nameEQ = escape(name) + "=";
    const cookies = document.cookie.split(';');

    for (let cookie of cookies) {
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) == 0) {
					return unescape(cookie.substring(nameEQ.length, cookie.length));
			}
		}

    return null;
}

/**
 * Erases the cookie but expiring
 */
function eraseCookie(name: string) {
    createCookie(name, "", -1);
}

export {
	createCookie,
	readCookie,
	eraseCookie
}
