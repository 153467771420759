import axios, { AxiosResponse } from 'axios';
import { IAddToCartDto, ICartControl, IJsonCart, ICartItem } from "../Cart/models";

export interface IUpdateCartItemResponse {
	success: boolean;
	shipmentDateChange: Date;
	cartItem: ICartItem;
	errorMessages: any[];
	refreshPageReason: string;
}

let $cartAxios = axios.create();
$cartAxios.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";

function fetchData<T>(req: Promise<T>){
	return req.catch(err => {
		console.log(err);
	});
}

class CartAPI {	
	getCartControl(data: IAddToCartDto): Promise<ICartControl> {
		return fetchData($cartAxios.post(
			`/cart/configurationcartcontrol`,
			{ data: JSON.stringify(data)  }
		).then(response => response.data));
	}

	getShowcasePricingSummary(data: IAddToCartDto): Promise<string> {
		return fetchData($cartAxios.post(
			`/cart/showcasepricesummary`, { addToCart: data }
		).then(response => response.data));
	}

	getOrderCutOffForUser(): Promise<any> {
		return fetchData($cartAxios.get('/cart/getordercutoffforuser').then(response => response.data));
	}

	getCart(): Promise<IJsonCart> {
		return fetchData($cartAxios.get("/cart/getcart").then(response => response.data?.jsonCart))

	}

	getSavedCartItemPage(data: {cursor: number, limit: number}): Promise<{CartItems: ICartItem[], HasNextPage: boolean, Count: number}>{
		return fetchData($cartAxios.get("/cart/getSavedCartItemPage", {params: data}).then(response => response.data))
	}

	getSavedCartHasNextPage(data: {cursor: number}): Promise<{HasNextPage: boolean, Count: number}> {
		return fetchData($cartAxios.get("/cart/getSavedCartHasNextPage", {params: data}).then(response => response.data))
	}

	getCartCount(): Promise<number> {
		return fetchData($cartAxios.get("/cart/getcartcount").then(response => response.data))
	}

	updateCartItem(payload): Promise<IUpdateCartItemResponse> {
		return fetchData($cartAxios.post("/cart/UpdateCartItemAjax", payload).then(response => response.data))
	}
}

export default new CartAPI();