import { contextModule } from "@shared/store/context";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const CONTEXT_MODULE_NAMESPACE = "contextStore";

export const store = new Vuex.Store({
    modules: { [CONTEXT_MODULE_NAMESPACE]: contextModule }
});