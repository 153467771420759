import Vue from 'vue';

/**
 * Infinite scroll mixin to help load more items in scrolling div.
 * Call in `mounted ()` like `this.infiniteScroll(...)`.
 */

export const infiniteScrollMixin = Vue.extend({
    props: {
        infiniteScrollState: {
            type: Object,
            default: () => ({
                loading: {
                    type: Boolean,
                    default: false
                },
                loadMore: {
                    type: Function,
                    default: () => { }
                },
                offset: {
                    type: Number,
                    default: 0
                }
            })
        }
    },
    methods: {
        /**
         * Init the infinite scroll event listener.
         * Using `offset` can help start load more before reaching the bottom.
         */
        infiniteScrollInit (loadMore: Function, offset = 0): void {
            this.infiniteScrollState.loadMore = loadMore
            this.infiniteScrollState.offset = offset
            this.$el.addEventListener('scroll', this.infiniteScrollHandler);
        },

        /**
         * Infinite scroll handler run on scroll event.
         */
        infiniteScrollHandler ({ target }): void {
            if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.infiniteScrollState.offset) {
                if (!this.infiniteScrollState.loading) {
                    this.infiniteScrollState.loadMore();
                    this.infiniteScrollState.loading = true;
                }
            } else {
                this.infiniteScrollState.loading = false;
            }
        }
    },
    beforeDestroy () {
        this.$el.removeEventListener('scroll', this.infiniteScrollHandler);
    }
})
