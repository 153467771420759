export interface ICurrency {
    Locale: string;
    Currency: string;
    Symbol: string;
    SymbolIsPrefix: boolean;
    GroupSeparator: string;
    DecimalDigits: number;
    DecimalSeparator: string;
}

export class Currency implements ICurrency {
    Locale!: string;
    Currency!: string;
    Symbol!: string;
    SymbolIsPrefix!: boolean;
    GroupSeparator!: string;
    DecimalDigits!: number;
    DecimalSeparator!: string;

    private readonly CurrencyFormatter!: Intl.NumberFormat;
    private readonly DecimalFormatter!: Intl.NumberFormat;
    private readonly WholeNumberFormater!: Intl.NumberFormat;

    constructor(data: ICurrency) {

        Object.assign(this, data) as ICurrency;

        const decimalFormatterOptions = {
            maximumFractionDigits: this.DecimalDigits,
            minimumFractionDigits: this.DecimalDigits
        };
        const wholeNumberFormatterOptions = {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        }

        if ('Intl' in window) {
            this.DecimalFormatter = new Intl.NumberFormat(this.Locale, decimalFormatterOptions);
            this.CurrencyFormatter = new Intl.NumberFormat(this.Locale, {
                ...decimalFormatterOptions,
                style: 'currency',
                currency: this.Currency
            });
            this.WholeNumberFormater = new Intl.NumberFormat(this.Locale, wholeNumberFormatterOptions);
        }
    }

    FormatMoney = (value: number) => {
        return this.CurrencyFormatter?.format(value) || value.formatMoney(this.Symbol, this.DecimalDigits, this.DecimalSeparator, this.GroupSeparator);
    }

    FormatMoneyWithoutSymbol = (value: number) => {
        return this.DecimalFormatter?.format(value) || this.FormatMoney(value).replace(this.Symbol, '');
    }

    FormatMoneyWithoutFractionOrSymbol = (value: number) => {
        return this.WholeNumberFormater?.format(value) || value.formatMoney(this.Symbol, 0, this.DecimalSeparator, this.GroupSeparator).replace(this.Symbol, '');
    }
}