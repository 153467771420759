/**
 * We need to define env placeholder var with type for webpack DefinePlugin to replace.
 */
interface env {
    NOTIFICATIONS_BASE_URL: string
}
declare const process: { env: env };

/**
 * Environment variables set by webpack DefinePlugin.
 */
const _env = {
    NOTIFICATIONS_BASE_URL: process.env.NOTIFICATIONS_BASE_URL
};

export { _env as env };
