import Vue from 'vue';
import _ from 'lodash';

export const viewportMixin = Vue.extend({
    data() {
        return {
            WindowSize: window.innerWidth
        }
    },
    computed: {
        ViewportLarge(): boolean { return this.WindowSize >= 1200; },
        ViewportMedium(): boolean { return this.WindowSize <= 1199 && this.WindowSize >= 992; },
        ViewportSmall(): boolean { return this.WindowSize <= 991 && this.WindowSize >= 768; },
        ViewportXSmall(): boolean { return this.WindowSize <= 767; },
        ViewportSmallAndDown(): boolean { return this.WindowSize <= 991 }
    },
    methods: {
        setWindowSize(size): void {
            this.WindowSize = size;
        }
    },
    mounted() {
        window.addEventListener('resize', _.throttle(() => this.setWindowSize(window.innerWidth), 40));
    }
})