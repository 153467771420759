/**
 * 
 * @param func 
 * The function to debounce
 * @param wait 
 * How long to wait (in milliseconds) before allowing the function to be invoked again
 * @param immediate 
 * Whether or not to immediately execute the function
 */
export default function debounce(func: Function, wait: number, immediate: boolean) {
	var timeout: number | null;
	return function(...args: any[]) {
		var context = this;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = window.setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
