import Vue from 'vue';
import App from './App.vue';
import CmsSection from '@shared/components/cms/cms-section.vue';
import axios from "axios";
import { store } from "./store/index";
import Tooltip from 'v-tooltip';
import NotificationsAPI from '@modules/api/notifications';

let defaultAxios = axios.create();
defaultAxios.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";
Vue.prototype.$axios = defaultAxios;

Vue.prototype.$notificationsAPI = NotificationsAPI;

const toolTipOptions = {
    popover: {
        //defaultInnerClass: "u-tooltip-inner u-popover-inner",
        defaultBaseClass: "u-tooltip u-popover"
    }
}

Vue.use(Tooltip, toolTipOptions);

Vue.component('CmsSection', CmsSection);

new Vue({
    el: '#full-width-header',
    store: store,
    render: h => h(App)
})