import axios from 'axios';
import { serializeQuery } from '../utility/mvc-query-string';

const api = axios.create({
    paramsSerializer: serializeQuery
});

export interface ILookupValue {
    ID: number;
    Type: string;
    Code: string;
    Order: number;
    Value: string;
    Value2: string;
    Value3: string;
    Value4: string;
    Value5: string;
    Value6: string;
    Value7: string;
    Value8: string;
    Value9: string;
    Value10: string;
}

class LookupAPI {
    
    private readonly _cache: { [key: string] : Promise<any> } = {}; 

    findByType(type: string): Promise<ILookupValue[]> {
        let cached = this._cache[type] || null;
        if (cached) {
            return cached.then(response => response);
        }

        const params = { type, api_fmt: "json" };

        return this._cache[type] = api.get("/lookups", { params }).then(response => response.data);
    }
}

export default new LookupAPI();