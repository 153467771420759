export type StorageType = 'temp' | 'session' | 'local';

export const withCaching = function<T>(cache: Cache, durationInMS: number, key: string, promise: JQueryPromise<T>, type: StorageType = 'session'): JQueryPromise<T> {
	return $.when(cache.Retrieve(key) || cache.StoreResult(key, promise, type, durationInMS));
};

export const withCachingFactory = function(cache: Cache, defaultCacheTime: number, defaultType: StorageType = 'session') {
	return <T>(key: string, promise: JQueryPromise<T>, durationInMS?: number, type?: StorageType): JQueryPromise<T> => {
		return withCaching(cache, durationInMS || defaultCacheTime, key, promise, type || defaultType);
	};
};

export class Cache {
	private readonly store: Record<string, any> = {};
	readonly Key: string;
	readonly Version: number;
	readonly Separator: string;
	readonly SessionAvailable: boolean;
	readonly LocalAvailable: boolean;

	constructor(key: string, version: number = 1, cacheSeparator: string = ";;") {
		this.Key = key;
		this.Version = version; //in case we persist and need to invalidate
		this.SessionAvailable = storageAvailable('sessionStorage');
		this.LocalAvailable = storageAvailable('localStorage');
		this.Separator = cacheSeparator;
	}

	Store<T>(key: string, payload: T, storageType: StorageType = 'session', expiresInMS?: number): T {
		try {
			const stringPayload = JSON.stringify(payload);
			const storeValue = expiresInMS ? this.formatCacheValue(stringPayload, expiresInMS) : stringPayload;
			const storeKey = this.formatCacheKey(key);

			if (storageType == 'local' && this.LocalAvailable)
				window.localStorage.setItem(storeKey, storeValue);
			else if (storageType == 'session' && this.SessionAvailable)
				window.sessionStorage.setItem(storeKey, storeValue);
			else
				this.store[storeKey] = storeValue;

		} catch (error) {
			throw new Error('Payload must be JSON compatible');
		}
		
		return payload;
	}

	StoreResult(key: string, promise: JQueryPromise<any>, storageType: StorageType = 'session', expiresInMS?: number): JQueryPromise<any> {
		return promise.then(result => this.Store(key, result, storageType, expiresInMS));
	}

	RunWithCaching<T>(key: string, func: (...args: any[]) => T): (...args: any[]) => T {
		return (...args: any[]) => this.Store(key, func(...args));
	}

	Retrieve(key: string): any {
		const cacheKey = this.formatCacheKey(key);
		const savedValue = this.getLocal(cacheKey) || this.getSession(cacheKey) || this.get(cacheKey);

		if (!savedValue)
			return null;

		const value = this.getValueIfNotExpired(savedValue);
		return value ? JSON.parse(value) : null;
	}

	private formatCacheKey(key: string) {
		return `${this.Key}-${this.Version}-${key}`;
	}

	private formatCacheValue(payload: string, expireTime: number) {
		return `${Date.now()}${this.Separator}${expireTime}${this.Separator}${payload}`;
	}

	private getValueIfNotExpired(value: string): any {
		const cacheParts = value.split(this.Separator);
		let now, initial, expiresInMS,
		valueToReturn = cacheParts[0];

		if (cacheParts.length === 3) {
			now = Date.now();
			initial = parseInt(cacheParts[0]);
			expiresInMS = parseInt(cacheParts[1]);
			valueToReturn = now - initial - expiresInMS < 0 ? cacheParts[2] : null;
		} else if (cacheParts.length > 3) {
			throw new Error(`Value saved containing "${this.Separator}", the cache separator.`);
		}

		return valueToReturn;
	}

	private getLocal(key: string): string {
		return this.LocalAvailable ? window.localStorage.getItem(key) : null;
	}

	private getSession(key: string): string {
		return this.SessionAvailable ? window.sessionStorage.getItem(key) : null;
	}

	private get(key: string): string {
		return this.store.hasOwnProperty(key) ? this.store[key] : null;
	}
}

function storageAvailable(type) {
    var storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}